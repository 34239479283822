.page-item{
    a{
      background-color: #0b0c28;
      border-color: #0b0c28;
      color: white;
    }
    &.active{
      a{
        background-color: #181b3a;
        border-color:#181b3a;
      }
      &:focus{
        background-color: #181b3a;
        border-color:#181b3a;
      }
    }
    &.disabled{
      a{
        background-color: #0b0c28;
        border-color: #0b0c28;
        color: gray;
      }
    }
  }
  
  table{
    table-layout: fixed;
    th,
    td{
      color:white
    }
    .th-id{
      width: 50px;
    }
    .th-email{
      width: 200px;
    }
    .th-image{
      width: 250px;
    }
    div{
      &.btn{
        &.btn-sm{
          color: white;
        }
      }
    }
  }
  
  
  
  
  
  
  
  
  
  