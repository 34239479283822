@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --primary-color: #181b3a;
  --secondary-color: #e3ef0d;
  // --secondary-color: #01d293;
  --body-bg: #0b0c28;
  --card-01-bg: linear-gradient(#ef621c, #e1424e);
  --card-02-bg: linear-gradient(#01d293, #56c57a);
  --card-03-bg: #725cff;
  --card-04-bg: #2884ff;
  --heading-color: #fff;
  --small-text-color: #808191;
  --recommend-car01-bg: #e1dfa4;
  --recommend-car02-bg: #e3ecf1;
  --recommend-car03-bg: #f4e3e5;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "DM Sans", sans-serif;
  background: var(--body-bg);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

.main__layout {
  margin-left: 260px;
}

img {
  width: 100%;
}
.card{
  background: var(--primary-color);
}
.Blank{
  // background-color: #181b3a;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;

  
  section {
      width: 100%;
      max-width: 420px;
      min-height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 1rem;
      background-color: #181b3a;
      border-radius: 5px;
  }
  
  form {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex-grow: 1;
      padding-bottom: 1rem;
  }
  
  a, a:visited {
      color: #fff;
  }
  
  input[type="text"],
  input[type="password"],
  button,
  textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    padding: 0.25rem;
    border-radius: 0.5rem;
  }
  
  label,
  button {
    margin-top: 1rem;
  }
  
  button {
    padding: 0.5rem;
  }
  
  li {
      list-style-type: none;
  }
  
  .instructions {
      font-size: 0.75rem;
      border-radius: 0.5rem;
      background: #000;
      color: #fff;
      padding: 0.25rem;
      position: relative;
      bottom: -10px;
  }
  
  .instructions > svg {
      margin-right: 0.25rem;
  }
  
  .offscreen {
      position: absolute;
      left: -9999px;
  }
  
  .hide {
      display: none;
  }
  
  .valid {
      color: limegreen;
      margin-left: 0.25rem;
  }
  
  .invalid {
      color: red;
      margin-left: 0.25rem;
  }
  
  .errmsg {
      background-color: lightpink;
      color: firebrick;
      font-weight: bold;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
  }
  
  .line {
      display: inline-block;
  }
  
  .flexGrow {
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
  }
  
  
}