// .dashboard {
//   margin-top: 100px;
//   padding: 0px 30px;
//   padding-bottom: 50px;
// }

.single__card {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
}
.ReportsObj{
  background: var(--card-01-bg);
}
.ContainersObj{
  background: var(--card-02-bg);
}
.UsersObj{
  background: var(--card-03-bg);
}
.NewsObj{
  background: var(--card-04-bg);
}

.card__content h4 {
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 400;
}

.card__content span {
  color: var(--heading-color);
  font-size: 2rem;
}

.card__icon {
  font-size: 2rem;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.845);
}

.statics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  margin-top: 2rem;
}

.stats {
  background: var(--primary-color);
  padding: 30px;
  border-radius: 5px;
  height: 320px;
  padding-bottom: 50px;
}

.tooltip__style {
  background: var(--body-bg) !important;
  color: #fff;
}

.stats__title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.recommend__cars-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2rem;
  column-gap: 2rem;
}

.recommend__car-card {
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.recommend__car-card:nth-child(1) {
  background: var(--recommend-car01-bg);
}
.recommend__car-card:nth-child(2) {
  background: var(--recommend-car02-bg);
}
.recommend__car-card:nth-child(3) {
  background: var(--recommend-car03-bg);
}

.recommend__car-top h5 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  color: var(--body-bg);
  margin-bottom: 10px;
}

.recommend__car-top h5 span i {
  font-size: 1.2rem;
}

.recommend__car-bottom h4 {
  font-size: 1.3rem;
  margin: 10px 0px;
}

.recommend__car-other {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recommend__icons {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.recommend__icons p:nth-child(1) {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
